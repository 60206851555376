import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import { message, Modal, Slider } from 'antd'
import axios from 'axios'
import { strapiURL } from '../../config'
import { useDispatch } from 'react-redux'
import { setProfileData } from '../../redux/actions/user'
import { getCroppedImg } from '../../functions'

const UpdateImageModal = ({ modalOpen, userProfile, setModalOpen }) => {
  const dispatch = useDispatch()
  const [imageSrc, setImageSrc] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)
  const [currentImage, setCurrentImage] = useState(null)
  const [currentFile, setCurrentFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const closeModalHandler = () => {
    if (loading) {
      return
    }
    setModalOpen(false)
    setCurrentImage(null)
    setCurrentImage(null)
  }
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )
      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
    setZoom(1)
    setCrop({ x: 0, y: 0 })
    setRotation(0)
  }, [imageSrc, croppedAreaPixels, rotation])

  const undoCroppedImage = () => {
    setCroppedImage(null)
  }

  const getBase64 = async (img, callback) => {
    let name = img.name
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      callback({ name: name, imageUrl: reader.result })
    })
    reader.readAsDataURL(img)
  }
  const fileAddHandler = async e => {
    let file = e?.target?.files[0]
    let imageDataUrl = await readFile(file)
    if (file) {
      setCurrentFile(file)
      await getBase64(file, setCurrentImage, setCroppedImage)
    }
    setImageSrc(imageDataUrl)
  }

  const uploadHandler = async () => {
    setLoading(true)
    const finalImage = await getCroppedImg(
      imageSrc,
      croppedAreaPixels,
      rotation
    )

    var bodyFormData = new FormData()
    bodyFormData.append(
      'files.image',
      !croppedImage ? finalImage : croppedImage
    )
    bodyFormData.append(
      'data',
      JSON.stringify({
        id: userProfile.id,
      })
    )

    axios({
      method: 'post',
      url: strapiURL + `/employers/image/update`,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {
        dispatch(
          setProfileData({
            ...userProfile,
            image: response.data.image,
          })
        )
        setLoading(false)
        closeModalHandler()
        setCroppedImage(null)
      })
      .catch(function (err) {
        message.error(err?.response?.data?.message[0]?.error)
        setLoading(false)
        setLoading(false)
      })
  }

  const readFile = file => {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }
  return (
    <Modal
      width={800}
      footer={null}
      onCancel={closeModalHandler}
      visible={modalOpen}
      okText="Save"
      className="p-0 candidateAlertModal shadow-lg"
    >
      <div className="modal-head bg-voyage py-5 px-5 rounded-tl rounded-tr flex items-center justify-between">
        <p className="font-sans font-bold text-base text-white mb-0">
          Upload Logo
        </p>
        <img
          onClick={() => closeModalHandler()}
          className="w-4 cursor-pointer mb-0"
          src="/icons/icon-modal-close.svg"
          alt="Close modal icon"
        />
      </div>
      <div className="bg-white px-6 py-8 rounded-bl rounded-br">
        {!currentImage ? (
          <div className="w-[93%] md:w-9/12 mx-auto">
            <div
              className={`flex flex-col bg-fileBg border border-voyage border-dashed rounded cursor-pointer relative py-16 flex items-center justify-center`}
            >
              <img src="/images/image-modal-logo.svg" />
              {!currentImage && (
                <input
                  onChange={e => fileAddHandler(e)}
                  disabled={currentImage}
                  type="file"
                  accept=".jpeg,.png"
                  className="opacity-0 w-full absolute inset-0 cursor-pointer"
                />
              )}

              {currentImage ? (
                <p className="font-sans text-sm font-semibold mb-0">
                  {currentImage.name || ''}
                </p>
              ) : (
                <>
                  <p className="font-sans text-sm font-semibold mb-0  text-center">
                    Drag and drop or{' '}
                    <span className="underline text-voyage">Upload</span> your
                    file
                  </p>
                  <p className="font-sans text-[10px] opacity-50 font-semibold mt-2 mb-0 text-center">
                    For best fit, we recommend your image be a 255 x 255 px
                    .jpg, .png, or .jpeg
                  </p>
                  <p className="font-sans text-[10px] opacity-50 font-semibold mt-1 mb-0  text-center">
                    Support: JPG, PNG
                  </p>
                </>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className="relative h-[14rem]">
              <Cropper
                image={
                  !croppedImage
                    ? currentImage?.imageUrl
                    : URL.createObjectURL(croppedImage) || ''
                }
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                onRotationChange={setRotation}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>

            <div className="flex justify-center flex-col items-center">
              {!croppedImage && (
                <>
                  <div className="flex justify-between items-center w-full md:w-[50%] mt-4">
                    <div className="w-[30%] md:w-[20%] mr-4 md:mr-0">
                      <span>Zoom</span>
                    </div>
                    <Slider
                      className="w-[78%] mx-auto"
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      range
                      onChange={(event, zoom) => setZoom(event)}
                    />
                  </div>
                  <div className="flex justify-between items-center w-full md:w-[50%] mt-4">
                    <div className="w-[30%] md:w-[20%] mr-4 md:mr-0">
                      <span>Rotation</span>
                    </div>
                    <Slider
                      className="w-[78%] mx-auto"
                      value={rotation}
                      min={0}
                      max={360}
                      step={1}
                      range
                      onChange={(event, rotation) => setRotation(event)}
                    />
                  </div>
                  <span
                    onClick={() => {
                      showCroppedImage()
                    }}
                    className="underline text-center mt-2 cursor-pointer w-full mx-auto"
                  >
                    Crop
                  </span>
                </>
              )}
              {croppedImage && (
                <span
                  onClick={() => {
                    undoCroppedImage()
                  }}
                  className="underline text-center mt-2 cursor-pointer w-full mx-auto"
                >
                  Undo
                </span>
              )}
              <span
                onClick={() => {
                  setCurrentFile(null)
                  setCurrentImage(null)
                  setCroppedImage(null)
                }}
                className="underline text-center mt-2 cursor-pointer w-full mx-auto"
              >
                Change
              </span>
              <button
                onClick={() => uploadHandler()}
                className="bg-voyage py-3 px-8 font-sans text-white text-sm font-bold rounded mx-auto mt-4"
              >
                {loading ? 'Uploading...' : 'Upload'}
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}
export default UpdateImageModal
