import React from 'react'
import EmployerCompanyProfile from '../../components/employerDashboard/companyProfile'
import Layout from '../../components/employerDashboard/layout'
const EmployerCompanyProfilePage = ({ location }) => {
  return (
    <Layout title="Company Profile | MedReps.com" location={location}>
      <EmployerCompanyProfile />
    </Layout>
  )
}
export default EmployerCompanyProfilePage
