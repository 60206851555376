import React, { useState, useEffect, useMemo } from 'react'
import { getDescription, nameReturnHandler } from '../../../functions'
import { Form, Input, Select, message } from 'antd'
import 'react-quill/dist/quill.snow.css'
import UpdateImageModal from '../../Modals/updateImageModal'
import axios from 'axios'
import { strapiURL } from '../../../config'
import { useDispatch } from 'react-redux'
import { setProfileData } from '../../../redux/actions/user'
import cookie from 'react-cookies'
import GooglePlacesAutocomplete from '../../google-place-autocomplete'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'
import { getAddressObject } from '../../../functions'

const CancelToken = axios.CancelToken
let source

const EmployerCompanyProfile = ({ userProfile, setUserProfile }) => {
  const dispatch = useDispatch()
  const [showChange, setShowChange] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [cancelling, setCancelling] = useState(false)
  const [form] = Form.useForm()
  const { Option } = Select
  const [location, setLocation] = useState(null)

  let ReactQuill
  if (typeof window !== 'undefined') {
    ReactQuill = require('react-quill')
  }

  useEffect(() => {
    if (userProfile && !modalOpen) {
      form.setFieldsValue({
        name: userProfile.name || '',
        company_email: userProfile.company_email || '',
        phone: userProfile.phone || '',
        website: userProfile.website || '',
        description: userProfile.description || '',
        staffing_firm: userProfile.staffing_firm ? 'yes' : 'no',
      })
      setLocation(userProfile.location?.placePredict)
    }
  }, [userProfile])

  useEffect(() => {
    form.setFieldsValue({
      location,
    })
  }, [location, form])

  const updateProfileHandler = async values => {
    let data = { ...values, description: getDescription(values.description) }
    setLoading(true)

    if (values.website && !values.website.includes('http')) {
      form.setFields([
        {
          name: 'website',
          errors: ['Enter a valid http or https url.'],
        },
      ])
      return
    }

    data.staffing_firm = values.staffing_firm === 'yes' ? true : false

    /* Location Process */
    if (values.location?.value?.place_id) {
      const geocodeData = await geocodeByPlaceId(values.location.value.place_id)
      if (geocodeData[0]) {
        const addressObject = getAddressObject(
          geocodeData[0].address_components
        )
        const locationObject = {
          location: {
            ...addressObject,
            formattedAddress: geocodeData[0].formatted_address,
            latitude: geocodeData[0].geometry.location.lat(),
            longitude: geocodeData[0].geometry.location.lng(),
            place_id: values.location.value.place_id,
          },
          placePredict: values.location,
        }
        data = {
          ...data,
          location: locationObject,
        }
      }
    } else {
      data = {
        ...data,
        location: userProfile.location || {},
      }
    }

    source = CancelToken.source()

    axios
      .put(
        strapiURL + `/employers/${userProfile?.id}`,
        {
          ...data,
        },
        {
          cancelToken: source.token
        }
      )
      .then(res => {
        setLoading(false)
        message.success('Your profile has been updated')
        dispatch(
          setProfileData({
            ...userProfile,
            name: values.name,
            company_email: values.company_email,
            phone: values.phone,
            website: values.website,
            description: getDescription(values.description),
            location: data.location,
            staffing_firm: data.staffing_firm,
          })
        )
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          setLoading(false)
          let _data = {
            name: userProfile.name || '',
            company_email: userProfile.company_email || '',
            phone: userProfile.phone || '',
            website: userProfile.website || '',
            description: userProfile.description || '',
            location: null,
            staffing_firm: userProfile.staffing_firm || false,
          }
          setCancelling(true)
          axios
            .put(strapiURL + `/employers/${userProfile?.id}`, _data)
            .then(res => {
              message.info('Update cancelled')
              setCancelling(false)
            })
            .catch(err => {
              setCancelling(false)
            })
          form.setFieldsValue({ ..._data })
        } else {
          message.error('Something went wrong.')
        }
      })
  }

  const locationValue = useMemo(() => {
    return location?.value?.place_id
      ? location
      : location?.label
      ? {
          ...location,
          value: {},
        }
      : null
  }, [location])

  return (
    <div className="mt-6 lg:mt-0">
      <h1 className="font-playfair text-headingThree text-merlot">
        Company Profile
      </h1>
      <p className="text-base text-[#000000d9]">
        Your company profile will appear below each of your posted job
        descriptions
      </p>
      {!userProfile ? (
        <div
          className="w-full min-h-900px bg-no-repeat bg-contain"
          style={{
            backgroundImage: `url(/loaders/company-profile-loader.png)`,
          }}
        />
      ) : (
        <div className="mt-16">
          {userProfile?.image ? (
            <div
              className="relative h-24 w-24 overflow-hidden"
              onClick={() => setModalOpen(true)}
              onMouseEnter={() => {
                setShowChange(true)
              }}
              onMouseLeave={() => {
                setShowChange(false)
              }}
            >
              <img
                className="cursor-pointer h-24 w-24"
                src={userProfile.image?.url || ''}
                alt="user-image"
              />
              <div
                className={` bg-black/[0.3] w-full absolute bottom-0 text-center py-1 cursor-pointer ${
                  showChange ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <span className="text-white text-xs font-bold font-sans">
                  Change
                </span>
              </div>
            </div>
          ) : (
            <div
              onClick={() => setModalOpen(true)}
              onMouseEnter={() => {
                setShowChange(true)
              }}
              onMouseLeave={() => {
                setShowChange(false)
              }}
              className="h-24 w-24 bg-gray-100 bg-[#01567c] relative cursor-pointer overflow-hidden cursor-pointer flex items-center"
            >
              <p className="mb-0 font-sans text-3xl w-full text-center text-white font-semibold">
                {nameReturnHandler(userProfile).letter}
              </p>
              <div
                className={`w-full bg-black/[0.3] absolute bottom-0 -mt-2 flex justify-center py-1 ${
                  showChange ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <span className="text-white text-xs font-bold font-sans">
                  Add
                </span>
              </div>
            </div>
          )}
          <Form
            className="pt-12 flex justify-between w-12/12 lg:w-9/12 flex-wrap"
            form={form}
            name="candidate-form"
            layout="vertical"
            onFinish={updateProfileHandler}
          >
            <div className="w-full lg:w-48%">
              <Form.Item
                name="name"
                label="Company Name"
                className="theme-form-item w-full mb-8"
                rules={[
                  {
                    required: true,
                    message: 'Required.',
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Enter Name"
                  className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                />
              </Form.Item>
            </div>
            <div className="w-full lg:w-48%">
              <Form.Item
                name="company_email"
                label="Company Email Address"
                className="theme-form-item w-full mb-8"
                rules={[
                  {
                    type: 'email',
                    message: 'Enter a valid Email.',
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder="Enter Email Address"
                  className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                />
              </Form.Item>
            </div>
            <div className="w-full lg:w-48%">
              <Form.Item
                name="phone"
                label="Company Phone"
                className="theme-form-item w-full mb-8"
              >
                <Input
                  type="text"
                  placeholder="Enter Phone"
                  maxLength={12}
                  onKeyPress={event => {
                    if (!/[0-9+]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                  className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                />
              </Form.Item>
            </div>
            <div className="w-full lg:w-48%">
              <Form.Item
                name="website"
                label="Company Website URL"
                className="theme-form-item w-full mb-8"
                rules={[
                  {
                    type: 'url',
                    message: 'Enter a valid http or https url.',
                  },
                ]}
              >
                <Input
                  placeholder="Enter Website URL "
                  className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                />
              </Form.Item>
            </div>
            <div className="w-full">
              <Form.Item
                name="staffing_firm"
                label="Is your organization a recruitment/staffing company?"
                className="theme-form-item w-full mb-8"
              >
                <Select>
                  <Option value="yes">Yes</Option>
                  <Option value="no">No</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="w-full">
              <Form.Item
                name="location"
                label="Location"
                className="google-place-autocomplete w-full mb-8"
              >
                <GooglePlacesAutocomplete
                  value={locationValue}
                  onChange={val => setLocation(val)}
                />
              </Form.Item>
            </div>
            <div className="w-full">
              <Form.Item
                className="theme-form-item w-full mb-8"
                name="description"
                label="About"
              >
                {typeof window !== 'undefined' && (
                  <ReactQuill
                    className="quill-editor placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                    theme="snow"
                  />
                )}
              </Form.Item>
            </div>
            <div className="w-full flex flex-col lg:flex-row">
              <div className="flex flex-1">
                <button className="bg-voyage py-3 px-8 font-sans text-white text-sm font-bold rounded mr-2">
                  {loading ? 'Saving...' : 'Save'}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    source.cancel()
                  }}
                  disabled={!loading}
                  className="bg-[#E5EDF0] py-3 px-8 font-sans text-voyage text-sm font-bold rounded mr-2"
                >
                  {cancelling ? 'cancelling...' : 'Cancel'}
                </button>
              </div>
            </div>
          </Form>
        </div>
      )}
      <UpdateImageModal
        setUserProfile={setUserProfile}
        userProfile={userProfile}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </div>
  )
}
export default EmployerCompanyProfile
